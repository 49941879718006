/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from "../Helper/AxiosInstance";
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setYouLoggedIn } from '../Redux/action/dataslice';

const YoutubeCallback = () => {
    const token = sessionStorage.getItem('token');
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [youtubeUrl, setYoutubeUrl] = useState('');
    const [youtubeChannelName, setYoutubeChannelName] = useState('');
    const [youtubeSubscriberCount, setYoutubeSubscriberCount] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const code = query.get('code');
        if (code) {
            console.log(code);
            handleFetchChannelDetails(code);
        }
    }, [location]);

    const handleFetchChannelDetails = async (code) => {
        setLoading(true);
        try {
            const response = await axiosInstance.post(`/quantum-share/youtube/user/verify-token?code=${code}`, code, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            const { youtubeUrl, youtubeChannelName, youtubeSubscriberCount } = response.data.data;
            setYoutubeUrl(youtubeUrl);
            setYoutubeChannelName(youtubeChannelName);
            setYoutubeSubscriberCount(youtubeSubscriberCount);
            dispatch(setYouLoggedIn(true))
            navigate("/social-integration");
            toast.success("Connected to YouTube!");
        } catch (error) {
            console.error('Error fetching channel details:', error);
            toast.error("Error Connecting to Youtube. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>

        </>
    )
}

export default YoutubeCallback